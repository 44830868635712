
.bg-ic-orange{
    background-color: #ebb000 !important;
    border-bottom: 2px solid #2d0007;
}

.form-check-input-terms[type="checkbox"]{
    margin-left: 0px !important;
    margin-top: 0px !important;
}
