
a { color: #000; text-decoration:underline; }

.bg-primary {
  background-color: #ebb001!important;
}


.bg-light { background-color: #EAEAEA!important; }

.text-primary {
  color: #ebb001!important;
}


.btn-primary {
  background-color: #ebb001!important;
  border-color: #ebb001!important;
  color: #000!important;
}

.top-nav a { font-size: 1rem; color: #000; text-decoration: none; }

header img.brand { filter: invert(1); transition: width 0.5s ease; }
header .nav a { color: #FFF; }
header .nav a:active, header .nav a:focus, header .nav a:hover { color: #ebb001!important; }
header button { border-color: #FFF!important; color: #FFF!important; }
header button:hover { background: transparent!important; }

header.header-scroll { margin-top: 0; padding-top: 0px!important; padding-bottom: 0px!important; background: #ebb001; }
header.header-scroll .nav a { color: #000; }
header.header-scroll .nav a:hover { color: #000!important; }
header.header-scroll button { border-color: #000!important; color: #000!important;  }
header.header-scroll button:hover { background: #000!important; border-color: #FFF!important; color: #FFF!important; }
header.header-scroll img.brand { width: 70%!important; filter: invert(0); }

.featured {
  color: #FFF;
  background: url("https://cdn.ajudeumacausa.com.br/ic-assets/Instituto_Caramelo_B.jpeg") center no-repeat; background-size: cover;
}

.featured-cta p { color: #555; }

.description-steps div:hover { background: #000; color: #FFF; }

footer img.brand { filter: invert(1); }
footer h5 { color: #ebb001; }
footer a { color: #FFF; }

.modal-header { background: #ebb001; color: #000; }


/*
  style.css
 */
.top-nav { padding: 5px 0; }
.top-nav .social-media i { font-size: 1.5rem; }

header { margin-top: 30px; transition: background 0.5s ease; }
header .nav a { text-transform: uppercase; font-weight:500; }
header .nav a.active { border-bottom: 2px solid #000; }

.featured { height: 90vh; position: relative; }
.featured .overlay { position: absolute; background: #000; opacity: 0.7; width:100%; height: 100%; z-index:1; }
.featured .container { position: relative; height: 100%; z-index: 2;}
.featured-cta { position: relative; top: 95px; background: #FFF; color: #000; border-radius: 10px; padding: 40px; }
.featured-cta img { position: absolute; width: 150px; margin-top: -160px; }

.featured-cta p { margin-bottom: 7px; }

.prize {display: inline-block; border-radius: 100%; overflow: hidden; margin-right: 15px; }
.prize-square {display: inline-block; border-radius: 5%; overflow: hidden; margin-right: 15px; }

h1 { font-weight: bold; font-size: 3rem; }
h2 { font-size: 1.5rem; font-weight: bold; }

.cta-wrapper { position: relative; z-index:2; }
.cta-bar { border-radius: 10px; position: relative; margin-top: -80px; background: #FFF; z-index: 3; box-shadow: 2px 5px 12px 0px #33333326; }
.cta-bar div:last-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px; }
.cta-bar .col { text-align: center; padding: 30px 0; }
.cta-bar h5 { font-size: 1rem; font-weight: bold; text-transform: uppercase; }
.cta-bar h4 { font-size: 2rem; font-weight: bold; font-weight: bold; }
.cta-bar hr.divider { margin: 15px auto; }

hr.divider { width: 50px; border: 1px solid #999; opacity: 1; display: block; margin: 15px 0; }
hr.divider-center { margin: 15px auto; }

p.intro { width: 50%; margin: 20px auto; font-size: 1.2rem; margin-bottom: 50px; }

.description-steps {  width: 350px; height: 340px; padding: 0 20px; }
.description-steps > div { display: flex; flex-direction: column; justify-content: center; width: 300px; height: 300px; text-align: center; border-radius: 100%; border: 7px solid #000; transition: background 0.2s ease; }
.description-steps i { font-size: 3rem; }
.description-steps h3 { font-size: 2rem; font-weight: bold; }
.description-steps p { font-weight: 600; margin-bottom: 2px; }

.btn { font-weight: 500; }

.sponsors img { display: block; margin: 0 auto; opacity: 0.5; transition: opacity 0.3s ease; }
.sponsors img:hover { opacity: 1; }

footer h5 { font-size: 0.7rem; }
footer ul { margin: 0; padding: 0; }

.disclaimer { font-size: 0.8rem; }

@media only screen and (max-width: 768px)
and (orientation : portrait)  {

  .top-nav .social-media { padding: 10px 0; }
  .top-nav .social-media i { font-size: 1.5rem; }

  header { margin-top: 80px; }

  .featured { height: auto; padding-bottom: 50px; }
  .featured > .container { padding-top: 200px; text-align: center; }

  .header-scroll .participe-wrapper { display: none; }
  .prize { margin: 40px 0; }
  .prize img { width:120px; }

  .featured-cta { padding: 30px; margin-left: 15px; margin-right: 15px; }
  .featured-cta img { margin-top: -153px; margin-left:-80px; }

  .cta-bar { margin: 25px 15px 0 25px; }
  .cta-bar div:last-child { border-top-right-radius: 0; border-bottom-left-radius: 10px; }

  #sobre { text-align: center; margin: 0 15px; }
  #sobre img { margin-top :20px; }

  hr.divider { display: block; margin: 15px auto; }

  p.intro { width: 80%; }

  .slick-next { right: 0; }
  .slick-prev { left: 0; }

  footer { padding: 0 15px; text-align: center; }
  footer h5 { margin-top: 30px; }
}


